<script>
import debounce from 'lodash/debounce';
import Picker from 'vanilla-picker';

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    setPicker() {
      const picker = new Picker({
        parent: this.$refs.picker,
        alpha: false,
        popup: false,
        color: this.value,
      });

      picker.onChange = debounce(
        (color) => this.$emit('input', color.hex.slice(0, 7)),
        250,
      );
      picker.onDone = () => this.$refs.dropdown.toggle();

      this.picker = picker;
      this.$on('hook:destroyed', () => picker.destroy());
    },
    openPicker() {
      try {
        this.picker.setColor(this.value, true);
      } catch {
        // TODO : handle this
      }

      this.picker.openHandler();
    },
  },
  mounted() {
    this.setPicker();
  },
};
</script>

<template>
  <div class="field has-addons">
    <div class="control">
      <span :style="{background: value}" class="button is-static pl-6" />
    </div>
    <b-input
      @input="$emit('input', $event)"
      :value="value" @keydown.enter.prevent.native
      v-bind="$attrs"
    />
    <b-dropdown ref="dropdown" class="control" position="is-bottom-left">
      <template #trigger>
        <b-button
          type="is-primary is-flat"
          icon-left="eye-dropper"
          @click="openPicker"
        />
      </template>
      <b-dropdown-item custom>
        <div ref="picker" />
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
